var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "safetyAssessment-dialog-main" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.formData.id
            ? _vm.type == "detail"
              ? "安全考核查看"
              : "安全考核编辑"
            : "安全考核新增",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel, "head-add": _vm.toSave },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "124px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "考核对象", prop: "assObject" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.type == "detail" },
                  on: { change: _vm.groupChange },
                  model: {
                    value: _vm.formData.assObject,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "assObject", $$v)
                    },
                    expression: "formData.assObject",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "人员" } }),
                  _c("el-radio", { attrs: { label: "部门" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.formData.assObject == "人员"
            ? _c(
                "el-form-item",
                { attrs: { label: "人员", prop: "userId" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.type == "detail" },
                    on: {
                      focus: function ($event) {
                        return _vm.openDialog("userDialog")
                      },
                    },
                    model: {
                      value: _vm.formData.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userName", $$v)
                      },
                      expression: "formData.userName",
                    },
                  }),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "部门", prop: "deptId" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.type == "detail" },
                    on: {
                      focus: function ($event) {
                        return _vm.openDialog("deptDialog")
                      },
                    },
                    model: {
                      value: _vm.formData.deptName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "deptName", $$v)
                      },
                      expression: "formData.deptName",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-form-item",
            { attrs: { label: "考核日期", prop: "assTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                  disabled: _vm.type == "detail",
                },
                model: {
                  value: _vm.formData.assTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "assTime", $$v)
                  },
                  expression: "formData.assTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "set-width",
              attrs: { label: "考核原因", prop: "assReason" },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.assReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "assReason", $$v)
                  },
                  expression: "formData.assReason",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "扣分", prop: "points" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.points,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "points", _vm._n($$v))
                  },
                  expression: "formData.points",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "经济处罚（元）", prop: "fine" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.fine,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "fine", _vm._n($$v))
                  },
                  expression: "formData.fine",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "教育培训（学时）", prop: "eduTrain" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.eduTrain,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "eduTrain", _vm._n($$v))
                  },
                  expression: "formData.eduTrain",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "待岗（月）", prop: "awaitJob" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.awaitJob,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "awaitJob", _vm._n($$v))
                  },
                  expression: "formData.awaitJob",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "缴费期限", prop: "paymentTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                  disabled: _vm.type == "detail",
                },
                model: {
                  value: _vm.formData.paymentTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "paymentTime", $$v)
                  },
                  expression: "formData.paymentTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "set-width",
              attrs: { label: "考核描述", prop: "assDesc" },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", disabled: _vm.type == "detail" },
                model: {
                  value: _vm.formData.assDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "assDesc", $$v)
                  },
                  expression: "formData.assDesc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传附件" } },
            [
              _c(
                "el-upload",
                {
                  class: {
                    uoloadSty: _vm.showImg,
                    disUoloadSty: _vm.type == "detail",
                  },
                  attrs: {
                    disabled: _vm.type == "detail",
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                    accept: "*",
                    limit: 1,
                    "file-list": _vm.fileArray,
                    headers: _vm.headers,
                    "on-success": _vm.uploadSuccess,
                    "on-remove": _vm.handleRemove,
                    "on-exceed": _vm.uploadExceed,
                    "on-preview": _vm.handleFilePreview,
                    "before-upload": _vm.handleBeforeUpload,
                  },
                },
                [
                  _vm.type != "detail"
                    ? [
                        _c("el-button", { attrs: { size: "small" } }, [
                          _c("i", { staticClass: "el-icon-upload2" }),
                          _vm._v(" 上传文件"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.userDialog,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userDialog = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            attrs: { "dept-category": [2, 5] },
            on: { "select-data": _vm.userBack },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "部门选择", visible: _vm.deptDialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptDialog = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptCategory: [2, 5] },
            on: { "select-data": _vm.deptBack },
          }),
        ],
        1
      ),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }