<template>
    <div>
        <el-container>
            <el-main>
                <head-layout
                    head-title="会议基础信息"
                    :head-btn-options="headTopBtnOptions"
                    @head-cancel="handleHeadGoback"
                    @head-save="headSave(1)"
                    @head-save-back="headSave(2)"
                />
                <!-- <form-layout
                    ref="formLayout"
                    :column="formColumn"
                    :data-form.sync="dataForm"
                >
                </form-layout> -->
                <el-form ref="formLayout" :model="dataForm" :rules="rules" label-width="120px" :disabled="type == 'view'">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="组织名称" prop="belongOrgName">
                                <el-input v-model="dataForm.belongOrgName" placeholder="请填写" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="会议议题" prop="meetingName">
                                <el-input v-model="dataForm.meetingName" placeholder="请填写" maxlength="255"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="会议时间" prop="meetingDate">
                                <el-date-picker
                                    v-model="dataForm.meetingDate"
                                    type="datetime"
                                    format='yyyy-MM-dd HH:mm'
                                    valueFormat='yyyy-MM-dd HH:mm'
                                    placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="会议类型" :prop="type == 'view'|| !typeBoo ? 'meetingTypeName' : 'meetingType'">
                                <avue-select
                                    :props="{
                                        label: 'dictValue',
                                        value: 'dictKey',
                                    }"
                                    v-model="dataForm.meetingType"
                                    placeholder="请选择"
                                    :dic="meetingTypeList"
                                    @change="handleTypeChange"
                                    ></avue-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="安全组织" :prop="!this.soOrgBoo ? 'soOrgName' : 'soOrgId'">
                                <avue-select
                                    :props="{
                                        label: 'soOrgName',
                                        value: 'id',
                                    }"
                                    v-model="dataForm.soOrgId"
                                    placeholder="请选择"
                                    :dic="soOrgList"
                                    @change="handleSoOrgChange"
                                    ></avue-select>
                            </el-form-item> 
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="参会人数" prop="userCount">
                                <el-input-number v-model="dataForm.userCount" controls-position="right" :min="0" :precision="0" placeholder="请填写"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="描述" prop="remark">
                                <el-input type="textarea" v-model="dataForm.remark" placeholder="请输入描述" maxlength="255" show-word-limit></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-main>
        </el-container>
        <el-container>
            <el-main>
                <uploader
                    @getTableData="handleUpload"
                    :fileSize="500"
                    accept=".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls"
                    :multiple="true"
                    :fileOptions="fileOptions"
                    ref="uploader"
                    :gridRowBtn="fileGridRowBtn"
                    :disabled="type == 'view'"
                ></uploader>
            </el-main>
        </el-container>
        <template v-if="type!=='add'">
            <el-container>
                <el-main>
                    <head-layout
                        head-title="参会人员"
                        :head-btn-options="userBtnOptions"
                        @head-add="handleUserAdd"
                    />
                    <grid-head-layout
                        ref="gridHeadLayout"
                        :search-columns="searchColumns"
                        @grid-head-search="gridHeadSearch"
                        @grid-head-empty="gridHeadEmpty"
                    ></grid-head-layout>
                    <grid-layout
                        ref="gridLayOut"
                        :grid-row-btn="gridRowBtn"
                        :table-options="tableOptions"
                        :table-data="tableData"
                        :table-loading="tableLoading"
                        :data-total="page.total"
                        :page="page"
                        :editType="inline"
                        @page-current-change="handleCurrentChange"
                        @page-size-change="handleSizeChange"
                        @page-refresh-change="onLoad"
                        @row-remove="rowUserRemove"
                    >
                    </grid-layout>
                </el-main>
            </el-container>
        </template>

        <el-dialog title="人员选择" :visible.sync="deptShow" width="80%" top="8vh">
            <UserDeptMultipleDialog ref="UserDeptMultipleDialog"  @select-all-data="selectAllData" @closeDia="deptShow=false"></UserDeptMultipleDialog>
        </el-dialog>

    </div>
</template>

<script>
// import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { mettingHeadSubmit, mettingLinePage, mettingHeadDetail, mettingLineSubmitBatch, mettingLineRemove } from '@/api/responsibility/securityMetting'
import {mapGetters} from 'vuex'
import {downloadFileBlob} from "@/util/util";
import {dateFormat} from '@/util/date'
import UserDeptMultipleDialog from "@/views/components/UserDeptDialog/UserDeptMultipleDialog.vue"
import uploader from "@/components/file-upload/index";
import { getDictionaryCode } from "@/api/system/dictbiz";
import {getOrgList} from '@/api/responsibility/safetyOrg.js'
export default {
    components: {
        // FormLayout,
        HeadLayout,
        GridLayout,
        UserDeptMultipleDialog,
        uploader
    },

    data () {
        return {
            nodeData: {},
            type: '',
            dataForm: {
                belongOrgName: '',
                meetingName: '',
                meetingDate: '',
                meetingTypeName: '',
                meetingType: '',
                soOrgId: '',
                userCount: '',
                remark: '',
            },
            // fileData: [],
            fileLoading: false,
            tableLoading: false,
            tableData:[],
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            query: {},
            deptShow: false,
            fileOptions: {
                linklabel:'name',
                column: [
                {
                    label: "附件名",
                    prop: "name",
                    align: "left",
                    overHidden: true,
                },
                {
                    label: "附件格式",
                    prop: "extension",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: "上传人",
                    prop: "createUserName",
                    align: "center",
                    overHidden: true,
                },
                {
                    label: "上传时间",
                    prop: "createTime",
                    align: "center",
                    overHidden: true,
                },
                ],
            },
            fileGridRowBtn:[
                {
                    label: '下载',
                    emit: "row-download",
                },
                {
                    label: '删除',
                    emit: "row-del",
                },
            ],
            meetingTypeList: [],
            soOrgList: [],
            rules: {
                meetingName: [{ required: true, message: "请填写", trigger: "blur" }],
                meetingDate: [{ required: true, message: "请选择", trigger: "change" }],
                meetingTypeName: [{required: true,message: "请选择",trigger: "change"}],
                meetingType: [{required: true,message: "请选择",trigger: "change"}],
                userCount:[{ type: 'number', message: "请正确填写参会人数", trigger: "blur" }],
            } 
        }
    },

    watch: {
        dataForm: {
            handler(val) {
                console.log(val)
            }
        }
    },

    created () {
        this.nodeData = this.$route.query.row ? JSON.parse(this.$route.query.row) : {}
        this.type = this.$route.query.type
        getDictionaryCode('meeting_type').then(res => {
            this.meetingTypeList = res.data.data
        })
        getOrgList({ orgId: this.userInfo.dept_id }).then((res) => {
            this.soOrgList = res.data.data;
        })
        if(this.type == 'add'){
            this.dataForm.belongOrgId = this.nodeData.belongOrgId
            this.dataForm.belongOrgName = this.nodeData.belongOrgName
        }
        if(this.nodeData.id)this.getDetail(this.nodeData.id)
    },

    mounted () {
        if(this.type == 'view'){
            // console.log(123)
            // this.$refs.formLayout.allDisabled = true
        //   this.$refs.formLayout.$refs.form.allDisabled = true
        }
    },

    computed: {
        ...mapGetters(["permission", 'colorName',"userInfo"]),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.eduquestions_add, true),
                viewBtn: this.vaildData(this.permission.eduquestions_view, true),
                editBtn: this.vaildData(this.permission.eduquestions_edit, true),
                delBtn: this.vaildData(this.permission.eduquestions_delete, true),
            };
        },
      formColumn() {
        return [
          {
            label: "组织名称",
            prop: "belongOrgName",
            labelWidth: 120,
            span:8,
            disabled: true,
          },
          {
            label: "会议议题",
            prop: "meetingName",
            labelWidth: 120,
            placeholder: "请填写",
            span:8,
            maxlength: 255,
            rules: [{
              required: true,
              message: "请填写",
              trigger: "blur"
            }]
          },
          {
            label: "会议时间",
            prop: "meetingDate",
            labelWidth: 120,
            placeholder: "请选择",
            span:8,
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm', // 设置日期格式
            valueFormat: 'yyyy-MM-dd HH:mm', // 设置数据库中存储的日期格式
            rules: [{
              required: true,
              message: "请填写",
              trigger: "change"
            }]
          },
          {
            label: "会议类型",
            prop: this.type == 'view'|| !this.typeBoo ? 'meetingTypeName' : "meetingType",
            labelWidth: 120,
            placeholder: "请选择",
            span:8,
            type: this.type == 'view' ? 'input' : 'select',
            // dicUrl: '/api/sinoma-system/dict-biz/dictionary-tree?code=meeting_type',
            dicData: this.meetingTypeList,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            rules: [{
              required: true,
              message: "请填写",
              trigger: "change"
            }],
            change: (value)=>{
              let obj = this.meetingTypeList.find(val => val.dictKey == value.value)
              if(obj){
                this.dataForm.meetingTypeName = obj.dictValue
                this.dataForm.meetingType = obj.dictKey
              }
            }
          },
          {
            label: "安全组织",
            prop:!this.soOrgBoo ? 'soOrgName' : "soOrgId",
            labelWidth: 120,
            placeholder: "请选择",
            span:8,
            type: 'select',
            // dicUrl: `api/sinoma-hse-prj/soorg/list?orgId=${this.userInfo.dept_id}`,
            dicData: this.soOrgList,
            props: {
              label: 'soOrgName',
              value: 'id',
            },
          },
          {
            label: "参会人数",
            prop:  "userCount",
            labelWidth: 120,
            placeholder: "请填写",
            span: 8,
            type: 'number',
            precision: 0,
            min: 0,
            rules: [{
              type: 'number',
              message: "请正确填写参会人数",
              trigger: "blur"
            }],
          },
          {
            label: "描述",
            prop: "remark",
            labelWidth: 120,
            placeholder: "请输入描述",
            sortable: true,
            type: "textarea",
            overHidden: true,
            span: 24,
            minRows: "2",
            maxRows: "4",
            maxlength: 255,
            showWordLimit: true,
          },
        ]
      },
        headTopBtnOptions() {
            let buttonBtn = [];
            if (this.type != 'view') {
                buttonBtn.push(
                {
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-save",
                    type: "button",
                    icon: "",
                    btnOptType: 'save',
                    loading: this.saveLoading,
                });
                buttonBtn.push(
                {
                    label: this.$t(`cip.cmn.btn.addBtn`),
                    emit: "head-save-back",
                    type: "button",
                    icon: "",
                    btnOptType: 'saveBack',
                    loading: this.saveLoading,
                });
            }
            buttonBtn.push(
                {
                label: this.$t('cip.cmn.btn.celBtn'),
                emit: "head-cancel",
                type: "button",
                icon: "",
                });
            return buttonBtn;
        },

        userBtnOptions () {
            let buttonBtn = [];
            if(this.type!=='view'){
                buttonBtn.push({
                    label: "新增",
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
            }
            return buttonBtn;
        },

        searchColumns() {
            return [
                {
                    prop: "userName",
                    placeholder: "姓名",
                    span: 4,
                },
                {
                    prop: "orgName",
                    placeholder: "组织",
                    span: 4,
                },
            ]
        },

        tableOptions () {
            return{
                customRowAddBtn: false,
                menuWidth: 100,
                selection: false,
                index: true,
                indexLabel: "序号",
                column: [
                    {
                        label: "姓名",
                        prop: "userName",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: '单位',
                        prop: 'unitName',
                        align: "center",
                    },
                    {
                        label: "部门",
                        prop: "orgName",
                        align: "center",
                    },
                    {
                        label: "性别",
                        prop: "sex",
                        align: "center",
                        overHidden: true,
                        type: 'select',
                        dicUrl: "/api/sinoma-system/dict/dictionary?code=gender",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                    },
                ],
            }
        },
        gridRowBtn () {
            let buttonBtn = []
            if(this.type !== 'view'){
                buttonBtn.push({
                    label: "删除",
                    emit: "row-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'remove',
                })
            }
            return buttonBtn
        },

        typeBoo () {
            return this.meetingTypeList.find(val => val.dictKey == this.dataForm.meetingType);
        },

        soOrgBoo () {
            console.log(this.soOrgList,this.soOrgList.find(val => val.id == this.dataForm.soOrgId))
            return this.soOrgList.find(val => val.id == this.dataForm.soOrgId);
        }

    },

    methods: {
        getDetail (id) {
            mettingHeadDetail({id}).then(res => {
                const data = res.data.data
                this.dataForm = data
                this.dataForm.meetingType = data.meetingType.toString()
                // const fileData = JSON.parse(data.files)
                this.dataForm.soOrgId = data.soOrgId==-1 ? '' : data.soOrgId
                this.$refs.uploader.initTaleData(data.files)
                this.query.meetingHeadId = data.id
                this.dataForm.meetingDate = this.dataForm.meetingDate.slice(0,16)
                // console.log(2,this.dataForm.meetingDate)
                this.dataForm.userCount = data.userCount == -1 ? 0 : data.userCount
                if(!this.dataForm.meetingTypeName){
                    let obj = this.meetingTypeList.find(val=>val.dictKey == this.dataForm.meetingType)
                    this.dataForm.meetingTypeName = obj ? obj.dictValue : ''
                }
                this.onLoad(this.page)
            })
        },

        handleHeadGoback () {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        headSave (val) {
            console.log(this.dataForm)
            // this.$refs.formLayout.$refs.form.validate(valid => {
                this.$refs.formLayout.validate(valid => {
                if(valid){
                    if(!this.dataForm.id)this.dataForm.belongOrgId = this.nodeData.belongOrgId
                    this.dataForm.meetingDate = this.dataForm.meetingDate.slice(0,16) + ':00'
                    let obj = this.meetingTypeList.find(val=>val.dictKey==this.dataForm.meetingType)
                    if(obj){this.dataForm.meetingTypeName = obj.dictValue}
                  this.$loading();
                    mettingHeadSubmit({...this.dataForm}).then(res=>{
                        // this.$refs.formLayout.$refs.form.allDisabled = false
                        if(val==1 && !this.nodeData.id){
                            this.dataForm.id = res.data.data.id
                            this.type = 'edit'
                            this.$router.$avueRouter.closeTag();
                            this.$router.replace({
                                path: '/business/responsibility/securityMetting/edit',
                                query: {
                                    row: JSON.stringify({id:res.data.data.id}),
                                    type: 'edit',
                                }
                            });
                        }
                        if(val==2){
                            this.handleHeadGoback()
                        }
                      this.$loading().close();
                    })
                }
            })
        },

        handleReset (flag) {
            return false
        },

        handleUpload(data){
            this.dataForm.files = this.resetFileData(data)

        },
        //   组合附件数据
        resetFileData(data) {
            let arr = [];
            data.forEach((ele, ind) => {
                arr.push({
                createTime: ele.createTime,
                createUser: ele.createUser,
                createUserName: ele.createUserName,
                extension: ele.extension,
                fileSize: ele.fileSize,
                id: ele.id,
                path: ele.path,
                schedule: ele.schedule,
                uid: ele.uid,
                name: ele.name,
                size: ele.size,
                });
            });
            return JSON.stringify(arr);
        },

        handleUserAdd () {
            this.deptShow = true
        },

        getDate (data,format) {
            return dateFormat(data,format)
        },

        onLoad (page,params={},type) {
            this.tableLoading = true;
            this.page = page;
            this.query.meetingHeadId = this.dataForm.id
            mettingLinePage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.page.total=data.total
                if(type == 'userCount'){
                    this.dataForm.userCount = data.total
                }
                this.tableData = data.records ? data.records.map(val=>{
                    val.sex = val.sex.toString()
                    return val
                }) : [];
                this.tableLoading = false;


                // this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm)
        },
        // 搜索
        gridHeadSearch(query) {
            const data = this.query
            this.page.currentPage = 1;
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },
        // 重置
        gridHeadEmpty(query) {
            this.page.currentPage = 1;
            const data = this.query
            this.query = {...data, ...query};
            this.onLoad(this.page)
        },

        selectAllData (data) {
            console.log(data)
            const userData = data.selectAllData.map(val=>{
                return {
                    meetingHeadId: this.dataForm.id,
                    // orgId: data.treeDeptId,
                    userId: val.id,
                    userName: val.realName,
                    // orgName: data.treeDeptName,
                    orgId: val.deptId,
                    orgName: val.deptName,
                    sex: val.sex,
                    sexName: val.sexName,
                }
            })
            mettingLineSubmitBatch(userData).then(res=>{
                this.deptShow = false
                this.$message.success('人员添加成功')
                this.onLoad(this.page,{},'userCount')
            })
        },

        rowUserRemove (row) {
            this.$confirm("确定删除当前数据?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                mettingLineRemove(row.id).then(() => {
                    // this.onLoad(this.page);
                    this.onLoad(this.page,{},'userCount')
                    this.$message.success('操作成功！')
                })
            });

        },
        
        handleTypeChange (val) {
            let obj = this.meetingTypeList.find(v => v.dictKey == val)
              if(obj){
                this.dataForm.meetingTypeName = obj.dictValue
                this.dataForm.meetingType = obj.dictKey
              }
        },

        handleSoOrgChange (val) {
            let obj = this.soOrgList.find(v => v.id == val)
              if(obj){
                this.dataForm.soOrgName = obj.soOrgName
                this.dataForm.soOrgId = obj.id
              }
        },

    }
}
</script>

<style lang="scss" scoped>
.file-name{
    color: #6F91C1;
    cursor: pointer;
    text-decoration: underline;
}
.headInfo{
    padding: 15px 12px 30px 12px;
    font-size: 14px;
    color: #3f4448;
    label{
        width: 100px;
    }
}

.file-table{
    width: 100%;
    text-align: center;
    border-color: #cccccc;
    font-size: 14px;
    color: #606266;
}
::v-deep .el-input__inner{
    margin-right: 0 !important;
} 
</style>
